import axios from "axios";
import { Message, MessageBox, Loading } from "element-ui";
import store from "@/store";
import FingerprintJS from "fingerprintjs2";
import Cookies from "js-cookie"; // 导入 js-cookie
import router from "@/router"; // 引入路由实例
import { blobValidate } from "@/utils/validate"; // 引入路由实例
import { saveAs } from "file-saver";
import errorCode from "@/utils/errorCode";

let downloadLoadingInstance;
// import { getToken } from '@/utils/auth'

// 递归处理参数，去除字符串类型的前后空格
function trimParams(params) {
  if (typeof params === "string") {
    return params.trim();
  }
  if (Array.isArray(params)) {
    return params.map((item) => trimParams(item));
  }
  if (typeof params === "object" && params !== null) {
    return Object.keys(params).reduce((acc, key) => {
      acc[key] = trimParams(params[key]);
      return acc;
    }, {});
  }
  return params;
}

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 请求的基础URL
  timeout: 10000, // 请求超时时间
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
});
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 对应国际化资源文件后缀
axios.defaults.headers["Content-Language"] = "zh_CN";
function getFingerprint() {
  return new Promise((resolve, reject) => {
    FingerprintJS.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = FingerprintJS.x64hash128(values.join(""), 31);
      resolve(fingerprint);
      reject("");
    });
  });
}
// 请求拦截器
service.interceptors.request.use(
  async (config) => {
    try {
      // 处理 GET 请求的 params（添加时间戳前处理）如果isUpload存在说明是上传的接口，不做处理
      if (config.params && !config.isUpload) {
        config.params = trimParams(config.params);
      }
      // 处理非 GET 请求的 data（如 POST/PUT）如果isUpload存在说明是上传的接口，不做处理
      if (config.data && !config.isUpload) {
        config.data = trimParams(config.data);
      }
      // 如果是GET请求，自动添加时间戳参数
      if (config.method?.toLowerCase() === "get") {
        config.params = {
          ...config.params,
          _t: Date.now(),
        };
      }
      // 生成指纹并设置到请求头
      config.headers["Yacoo-FP"] = await getFingerprint();
      // 检查本地存储中的授权token并设置到请求头
      const authToken = Cookies.get("authToken");
      if (authToken) {
        config.headers["Authorization"] = authToken;
      }
      // 动态设置超时时间
      if (config.isUpload) {
        config.timeout = 20000; // 文件上传接口超时时间设置为8秒
      } else {
        config.timeout = 10000; // 默认超时时间10秒
      }
    } catch (error) {
      console.error("Error generating fingerprint:", error);
      return Promise.reject(error);
    }

    return config;
  },
  (error) => {
    console.error("request error", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    // 处理非200或10001的情况
    if (res.code !== 200 && res.code !== 100003) {
      if (res.code === 401) {
        Cookies.remove("authToken");
        localStorage.removeItem("userInfo");
        router.push("/login", () => {});
        // 跳转到登录页
        return {
          code: 401,
          message: "未授权，请重新登录",
        };
      } else if (res.code === 500) {
        Message({
          message: res.message,
          type: "error",
        });
        // 返回处理后的错误信息，但不影响后续代码
        return Promise.reject(new Error(res.message));
      } else if (res.code === 100001 || res.code === 100002) {
        //接口返回错误信息
        Message({
          message: res.message,
          type: "error",
        });
        // 返回处理后的错误信息，但不影响后续代码
        return Promise.reject(new Error(res.message));
      } else {
        Message({
          message: res.message,
          type: "error",
        });
        // 返回一个特定的响应
        return { code: res.code, message: res.message };
      }
    } else {
      // 正常返回数据
      return res;
    }
  },
  (error) => {
    console.error("response error", error);
    Message({
      message: error.message,
      type: "error",
      duration: 10 * 1000, //默认超时时间10秒
    });
    // 返回错误，但不中断后续代码执行
    return { code: -1, message: error.message };
  }
);

// 通用下载方法
export function download(url, data, filename, apiModule) {
  const isObject = url != null && typeof url === "object";
  const options = isObject ? url : { url, data, filename, apiModule };
  options.method = options.method ?? "POST";
  options.responseType = "blob";
  options.headers = {
    Authorization: Cookies.get("authToken"), // 可能需要加 Bearer
    "Content-Type": "application/json",
  };
  downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  return axios
    .request(options)
    .then(async (res) => {
      const isLogin = await blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data]);
        saveAs(blob, options.filename);
      } else {
        const resText = await res.data.text();
        const rspObj = JSON.parse(resText);
        const errMsg =
          errorCode[rspObj.code] || rspObj.msg || errorCode["default"];
        Message({
          message: errMsg,
          type: "error",
        });
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message({
        message: "下载文件出现错误，请联系管理员！",
        type: "error",
      });
      downloadLoadingInstance.close();
    });
}

export default service;
