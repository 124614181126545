import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import { cartNumApi } from "@/api/home/index"; // 引入接口方法
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
  },
  state: {
    currentUser: null, // 修改为 currentUser 以避免命名冲突
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || null,
    cartNum: 0, // 新增购物车数量状态
  },
  mutations: {
    setUser(state, user) {
      state.currentUser = user; // 同样修改为 currentUser
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
      // 同步更新 localStorage
      if (userInfo) {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
      } else {
        localStorage.removeItem("userInfo");
      }
    },
    SET_CART_NUM(state, num) {
      state.cartNum = num;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit("setUser", user);
          resolve();
        }, 1000);
      });
    },
    logout({ commit }) {
      commit("setUser", null);
    },
    setUserInfo({ commit }, userInfo) {
      commit("SET_USER_INFO", userInfo);
    },
    async fetchCartNum({ commit }) {
      // 检查 token 是否存在
      const authToken = Vue.prototype.$cookies.get("authToken");
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (authToken && userInfo) {
        let res = await cartNumApi();
        if (res.code === 200) {
          commit("SET_CART_NUM", res.data);
        }
      } else {
        commit("SET_CART_NUM", 0);
      }
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.currentUser, // 修改为 currentUser
    currentUser: (state) => state.currentUser, // 修改为 currentUser
    userInfo: (state) => state.userInfo,
    cartNum: (state) => state.cartNum,
  },
});
