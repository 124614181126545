import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyload from 'vue-lazyload'
import '@fortawesome/fontawesome-free/css/all.css';
import Carousel3d from 'vue-carousel-3d';
import CopyTextComponent from './components/Copy/CopyTextComponent.vue'; // 确保路径正确
import Cookies from 'js-cookie';
import {numberToCurrency} from '@/utils/auth';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

//计算全局挂载
import cal from './utils/calculation';
//节流 防抖
import * as utils from '@/utils/choke.js';

Vue.use(utils)//引用这个utils
Vue.prototype.$utils = utils //全局请求方法
Vue.prototype.$cookies = Cookies
Vue.use(ElementUI)
Vue.use(Carousel3d);
Vue.component('CopyTextComponent', CopyTextComponent);
Vue.prototype.cal = cal;
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})
Vue.filter('toFixed2', function (value) {
  if (!value) return '0.00'; // 处理空值或非数值情况
  if (value % 1 !== 0) { // 检查是否有小数部分
    return value;
  }
  return parseFloat(value).toFixed(2);
});

Vue.filter('toFixed4', function (value) {
  if (!value) return '0.0000'; // 处理空值或非数值情况
  if (value % 1 !== 0) { // 检查是否有小数部分
    return value;
  }
  return parseFloat(value).toFixed(4);
});

// 配置全局过滤器，实现数字千分位格式
Vue.filter('numberToCurrency', numberToCurrency)

// 配置全局过滤器，去除 HTML 标签
Vue.filter('stripHtml', function (value) {
  if (!value) return '';
  return value.replace(/<\/?[^>]+(>|$)/g, '');  // 正则去除 HTML 标签
});

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

Vue.directive("lazyload", {
  inserted(el) {
    const isIE = /msie|trident/i.test(navigator.userAgent);
    if (isIE) {
      const src = el.getAttribute("data-src");
      if (src) {
        el.src = src;
      }
    } else {
      const loadImage = (imageElement) => {
        const imageSrc = imageElement.dataset.src;
        if (imageSrc) {
          imageElement.src = imageSrc;
        }
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadImage(entry.target);
            observer.unobserve(entry.target); // 图片加载后取消观察
          }
        });
      });

      observer.observe(el);
    }
  },
});


let previousRoute = null;

router.beforeEach((to, from, next) => {
  previousRoute = from; // 保存上一个路由的信息
  next();
});


// 将 previousRoute 挂载到 Vue 原型上，方便全局访问
Vue.prototype.$previousRoute = () => previousRoute;

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
