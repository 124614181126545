import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import Cookies from 'js-cookie';  // 导入 js-cookie
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
    meta: { title: '生物试剂_化学试剂_化学试剂网_科研试剂采购平台_元素商城' }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      context: '欢迎登录',
      title: '欢迎登录元素商城'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/loginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      context: '用户注册',
      title: '用户注册-元素商城'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/RegiSter.vue')
  },
  {
    path: '/orderPay/:orderId',
    name: 'orderpaymentPage',
    meta: {
      context: '订单支付'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/orderpayment/orderpaymentPage.vue'),
  },
  {
    path: '/orderPayment/success',
    name: 'SuccessPage',
    meta: {
      context: '',
      title: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/SuccessPage.vue')
  },
  {
    path: '/verifyAccount/success',
    name: 'verifyAccountSuccess',
    meta: {
      context: '',
      title: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/SuccessPage.vue')
  },
  {
    path: '/infoCenter',
    name: 'infoCenter',
    meta: {
      context: '',
      title:'资讯中心-元素商城'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/infoCenter/index.vue')
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    meta: {
      context: '',
      title:'帮助中心'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/helpCenter/index.vue')
  },
  {
    path: '/pointsMall',
    name: 'pointsMall',
    meta: {
      context: '',
      title:'元宝甄选'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/pointsMall/index.vue'),
  },
  {
    path: '/pointsSubmitOrder',
    name: 'pointsSubmitOrder',
    meta: {
      context: '提交订单',
      title:'元宝甄选-提交订单'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/pointsMall/submitOrder/submitOrder.vue'),
  },
  {
    path: '/pointsSubmitOrder/exchangeSuccess',
    name: 'exchangeSuccess',
    meta: {
      context: '提交订单',
      title:''
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/pointsMall/exchangeSuccess.vue'),
  },
  {
    path: '/Recharge',
    name: 'Recharge',
    meta: {
      context: '充值余额',
      title:'充值-元素商城'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/recharge/rechargeBalance.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    meta: {
      context: '订单结算',
      title: '确认订单信息'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/payment/payView.vue')
  },
  {
    path: '/product/:goodsId',
    name: 'productDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/index.vue')
  },
  {
    path: '/pointsProduct/:pointGoodsId',
    name: 'pointsProductDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/pointsMall/pointsProduct/index.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      context: '购物车',
      title: '购物车'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cart/cartView.vue')
  },
  {
    path: '/searchResPage',
    name: 'searchResPage',
    meta: {
      context: '',
      title: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/search/searchResPage.vue')
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/index.vue'),
    redirect: '/userCenter/userCenterIndex', // 设置重定向
    children: [
      {
        path: 'userCenterIndex', 
        meta: {
          context: '用户中心',
          title:'用户中心-元素商城'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/userCenterIndex.vue'),
      },
      {
        path: 'myOrder',
        meta: {
          context: '用户中心',
          title:'我的订单'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myOrder/myOrder.vue'),
      },
      {
        path: 'myInvoice',
        meta: {
          context: '用户中心',
          title:'我的发票'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myInvoice/myInvoice.vue'),
      },
      {
        path: 'myAfterSales',
        meta: {
          context: '用户中心',
          title:'我的售后'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myAfterSales/index.vue'),
      },
      {
        path: 'myBalance',
        meta: {
          context: '用户中心',
          title:'我的余额'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myBalance/myBalance.vue'),
      },
      {
        path: 'historicalOrder',
        meta: {
          context: '用户中心',
          title:'历史订单'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/historicalOrder/historicalOrder.vue'),
      },
      {
        path: 'myCoupons',
        meta: {
          context: '用户中心',
          title:'我的优惠券'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myCoupons/myCoupons.vue'),
      },
      {
        path: 'creditLimit',
        meta: {
          context: '用户中心',
          title:'信用额度'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/creditLimit/creditLimit.vue'),
      },
      {
        path: 'myTeam',
        meta: {
          context: '用户中心',
          title:'我的团队'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myTeam/myTeam.vue'),
      },
      {
        path: 'deliveryAddress',
        meta: {
          context: '用户中心',
          title:'收货地址'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/deliveryAddress/deliveryAddress.vue'),
      },
      {
        path: 'invoiceHeader',
        meta: {
          context: '用户中心',
          title:'发票抬头'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/invoiceHeader/index.vue'),
      },
      {
        path: 'basicInformation',
        meta: {
          context: '用户中心',
          title:'我的信息'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/basicInformation/index.vue'),
      },
      {
        path: 'myMessage',
        meta: {
          context: '用户中心',
          title:'我的消息'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myMessage/index.vue'),
      },
      {
        path: 'suggestPage',
        meta: {
          context: '用户中心',
          title:'建议反馈'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/suggestPage/index.vue'),
      },
      {
        path: 'myInquiry',
        meta: {
          context: '用户中心',
          title:'我的询价'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myInquiry/index.vue'),
      },
      {
        path: 'myFrequentPurchases',
        meta: {
          context: '用户中心',
          title:'我的常购'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myFrequentPurchases/index.vue'),
      },
      {
        path: 'ingotOrder',
        meta: {
          context: '用户中心',
          title:'元宝订单'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/ingotOrder/ingotOrder.vue'),
      },
      {
        path: 'myIngot',
        meta: {
          context: '用户中心',
          title:'我的元宝'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myIngot/index.vue'),
      },
      {
        path: 'accountSecurity',
        meta: {
          context: '用户中心',
          title:'账户安全'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/accountSecurity/index.vue'),
      },
    ]
  },
  {
    path: '/userCenter/myOrder/myOrderDetails/:orderId',
    name: 'myOrderDetails',
    meta: {
      context: '我的订单',
      title:'订单详情'
    },
    component: () => import('../views/usercenter/components/orderRelated/myOrderDetails.vue'),
  },
  {
    path: '/userCenter/ingotOrder/ingotOrderDetails/:pointOrderId',
    name: 'ingotOrderDetails',
    meta: {
      context: '元宝订单',
      title:'订单详情'
    },
    component: () => import('../views/usercenter/components/ingotOrder/ingotOrderDetail/ingotOrderDetails.vue'),
  },
  {
    path: '/userCenter/historicalOrder/historicalOrderDetails/:orderId',
    name: 'historicalOrderDetails',
    meta: {
      context: '历史订单',
      title:'订单详情'
    },
    component: () => import('../views/usercenter/components/historicalOrder/historicalOrderDetails/index.vue'),
  },
  {
    path: '/userCenter/myOrder/invoiceRelated/:invoiceApplyId',
    name: 'invoiceRelated',
    meta: {
      context: '',
      title:'发票详情'
    },
    component: () => import('../views/usercenter/components/invoiceRelated/invoiceRelated.vue'),
  },
  {
    path: '/userCenter/creditLimit/billDetails/:customerId',
    name: 'billDetails',
    meta: {
      context: '',
      title:'未结清账单'
    },
    component: () => import('../views/usercenter/components/creditLimit/billDetails.vue'),
  },
  {
    path: '/ticketRelated/details/:workOrderId',
    name: 'ticketDetails',
    meta: {
      context: '',
      title:'工单详情'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/details.vue')
  },
  {
    path: '/ticketRelated/initiateAfterSales/:orderId',
    name: 'initiateAfterSales',
    meta: {
      context: '发起售后',
      title:'发起售后'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/initiateAfterSales.vue')
  }, 
  {
    path: '/userCenter/accountSecurity/modifyPhoneNumber',
    name: 'modifyPhoneNumber',
    meta: {
      context: '修改密码',
      title:'修改密码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/accountSecurity/modifyPhoneNumber.vue')
  },
  {
    path: '/ticketRelated/suggestionFeedback',
    name: 'suggestionFeedback',
    meta: {
      context: '发起建议反馈',
      title:'建议反馈'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/suggestionFeedback.vue')
  },
  {
    path: '/previewActivity/:goodsId',
    name: 'previewActivity',
    meta: {
      context: '',
      title:'活动专区'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/index.vue')
  },
  {
    path: '/activity/:goodsId',
    name: 'activity',
    meta: {
      context: '',
      title:'活动专区'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/index.vue')
  },
  {
    path: '/ticketRelated/initRequest',
    name: 'initRequest',
    meta: {
      context: '发起询价',
      title:'发起询价'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/initRequest.vue')
  },
  {
    path: '/advancedSearch',
    name: 'advancedSearch',
    meta: {
      title: '高级搜索-元素商城'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/search/advancedSearch.vue')
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    meta: {
      context: '重置密码',
      title:'重置密码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/ResetPassword.vue')
  },
  {
    path: '/verifyAccount',
    name: 'verifyAccount',
    meta: {
      context: '账户验证',
      title:'账户验证'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/verifyAccount/verifyAccount.vue')
  },
  {
    path: '/weixin_login',
    name: 'weixin_login',
    meta: {
      context: '绑定账户',
      title:'绑定账户'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/components/thirdPartyLogin.vue')
  },
  {
    path: '/zhifubao_login',
    name: 'zhifubao_login',
    meta: {
      context: '绑定账户',
      title:'绑定账户'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/components/thirdPartyLogin.vue')
  },
  // 404页面路由，必须放在最后
  {
    path: '*',
    name: 'NotFound',
    meta: {
      context: '',
      title:'404'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/components/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有保存的位置（如浏览器后退按钮），滚动到保存的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则，滚动到页面顶部
      return { x: 0, y: 0 };
    }
  }
})



router.beforeEach((to, from, next) => {

  const authToken = Cookies.get('authToken'); // 获取 token
  const userInfo = localStorage.getItem('userInfo'); // 获取 token
  if (to.path === '/login' && authToken && userInfo) {
    // 如果已经登录并且访问登录页面，则重定向到首页
    next({ path: '/home' });
  } else {
    next();
  }

  if (to.path === '/userCenter/userCenterIndex' && (!authToken || !userInfo)) {
    // 如果没有authToken或userInfo，则重定向到/login
    next('/login');
  } else {
    next(); // 否则继续导航
  }

  // 如果路由有设置 meta.title，就使用该标题
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '元素商城 b2star-生物试剂_化学试剂_化学试剂网_科研试剂采购平台'; // 如果没有设置标题，使用默认标题
    next();
  }
   

  // 在路由配置文件中

  // router.afterEach((to, from) => {
  //   if (from.path === '/verifyAccount') { 
  //     localStorage.removeItem('oldUserData');
  //   }
  // });
  
  // if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
  //   next({ name: 'Home' });
  // } else {
  //   next();
  // }
});

export default router
